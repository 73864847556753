(function(selector) {
    doc.on("click", selector, function(){
        $(this).toggleClass("state--focus").focusin();
        $(this).find("[aria-hidden]").attr("aria-hidden", false);
        $(this).one("blur",function() {
            $(this).removeClass("state--focus");
            $(this).find("[aria-hidden]").attr("aria-hidden", true);
        });
    });

    doc.on("click", `${selector} [data-option]`, function(e){
        $(this).closest(".part_ui_select").find("select").val($(this).data("option")).trigger("change");
    });

    doc.on("change", `${selector} select`, function(){
        if ($(this)[0].value === "") {
            $(this).parent().addClass("state--placeholder");
        } else {
            $(this).parent().removeClass("state--placeholder");
        }
    });
})(".part_ui_select");