let $ = jQuery,
    doc = $(document),
    win = $(window),
    html = $("html"),
    body = $("body"),
    lang = html.attr('lang'),
    locomotive,
    ssm_timeout = 0;

window.lui = {
    layout: {},
    comp: {},
    part: {},
    lib: {}
};

if ('serviceWorker' in navigator && location.protocol === "https:") {
    window.addEventListener('load', function() {
        if (!html.hasClass("no-sw")) {
            navigator.serviceWorker.register('/sw.js').catch(function(e) {
                console.error('Error during service worker registration:', e);
            });
        } else {
            navigator.serviceWorker.getRegistrations().then(function(registrations) {
                if (registrations.length > 0) {
                    for(let registration of registrations) {
                        registration.unregister()
                    }
                }
            });
        }
    });
}

(function locomotiveScroll() {
    bodyLoaded(function() {
        let inertia = 0.9;

        if(html.hasClass("firefox") && html.hasClass("macintel")) {
            inertia = 1;
        }

        locomotive = new LocomotiveScroll({
            el: document.querySelector('#layout_scroll'),
            smooth: true,
            getSpeed: true,
            getDirection: true,
            inertia: inertia,
            // smoothMobile: true
        });

        setTimeout(function () {
            locomotive.update();
        },1000);

        let lastScrollTop = 0;

        win.on("scroll", function(p) {
            let position = win.scrollTop();

            if (position > 5) {
                html.addClass("is--header-not-top");
            } else {
                html.removeClass("is--header-not-top is--header-pinned is--header-unpinned");
            }

            if (position > lastScrollTop){
                html.removeClass("is--header-pinned").addClass("is--header-unpinned");
            } else {
                html.removeClass("is--header-unpinned").addClass("is--header-pinned");
            }

            lastScrollTop = position <= 0 ? 0 : position;
        });
    })
})();

(function preloadPage() {
    if (document.body.classList.contains("body--preload")) {
        cssLoaded(function(){
            document.body.classList.remove("body--preload");
            setTimeout(function () {
                document.body.classList.add("body--loaded");
            }, 300);
        });
    }
})();

(function preloadFont() {
    if (typeof WebFontConfig === "undefined" && document.querySelector("[data-loadcss]") !== null) {
        if (typeof document.fonts !== "undefined") {
            document.fonts.ready.then(function () {
                document.documentElement.classList.add("wf-active");
            });
            setTimeout(()=> {
                if(!document.documentElement.classList.contains("wf-active")) {
                    document.documentElement.classList.add("wf-active");
                }
            },500);
        } else {
            document.documentElement.classList.add("wf-active");
        }
    }
})();

document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
});

if (!('scrollBehavior' in document.documentElement.style)) {
    seamless.polyfill();
}