$.lui("comp", ".comp_shop", function (selector) {

    selector.each(function () {
        let items = $(this).find(".wrp_comp_body");

        items.each(function () {
            let item = $(this);
            let text = item.find(".elm_comp_text").find(".part_ui_wsw"),
                paragraphs = text.children().not(":first-child"),
                showMore = item.find("[data-click=\"show-more\"]"),
                showMoreLng = showMore.data("lng");

            if (paragraphs.length > 1) {
                paragraphs.hide();
                locomotive.update();
            }
            else {
                showMore.remove();
            }

            showMore.on("click",function (e) {
                e.preventDefault();

                if(showMore.hasClass("state--active")) {
                    paragraphs.stop().slideUp(function () {
                        if(typeof locomotive !== "undefined") {
                            locomotive.update();
                        }
                    });
                    showMore.text(showMoreLng.showMore);
                    showMore.removeClass("state--active");
                }
                else {
                    paragraphs.stop().slideDown(function () {
                        if(typeof locomotive !== "undefined") {
                            locomotive.update();
                        }
                    });
                    showMore.text(showMoreLng.showLess);
                    showMore.addClass("state--active");
                }
            })
        })
    });
});