// $.lui("lib_ripple", ".part_ui_btn_icon, .part_ui_btn", function(selector) {
//     selector.lui_ripple();
// });

$.lui("lib_nativeSlider", "[data-lib-ns]", function(selector){
    selector.each(function(){
        lui_nativeSlider($(this)[0]);
    });
});

$.lui("lib_map", "[data-lib-map]", function(selector){
    selector.lib_map(selector.data("lib-map"));
});

$.lui("lib_gallery", "[data-lib-fancybox]", function(selector) {
    bodyLoaded(function(){
        if ($(selector).find("[data-lib-fancybox-item]").length) {
            $.importStyle(cdnjs.fancybox_css);
            $.importScript(cdnjs.fancybox, function(){
                $(selector).each(function(){
                    let id = $(this).data("lib-fancybox");
                    $(this).fancybox({
                        selector: `[data-lib-fancybox="${id}"] [data-lib-fancybox-item]`,
                        transitionEffect: "slide",
                        animationEffect: "zoom",
                        hideScrollbar: false,
                        buttons: [
                            "close"
                        ]
                    });
                });
            });
        }
    });
});

$.lui("lib_anchors", `a[href^="#"]`, function(selector) {
    selector.off("click.anchor").on("click.anchor", function() {
        let elm = $(this);
        let id = elm.attr("href").replace("#","");

        if (document.getElementById(id) !== null) {

            locomotive.scrollTo(document.getElementById(id),0);

            if (id.indexOf("layout") !== -1) {
                return false;
            }
        }
    });
});

(function fn_ajaxEvents() {
    doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let el = $(this);

        $.ajax({
            url: el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });
    });

    doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        frm.find('button[type=submit]').addClass('state--loading').attr('disabled', true);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
        }).done(function (payload) {
            fn_ajaxHandler(payload,function() {
                if(typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                    lui_dialog.open(payload.dialog);
                }
            });
        }).always(function () {
            frm.find('button[type=submit]').removeClass('state--loading').attr('disabled', false);
        });
    });

    doc.on('click', 'a[data-component-ajax]', function (e) {
        e.preventDefault();
        let url = $(this).attr('data-href');
        if (!url || !url.length) {
            url = $(this).attr('href');
        }

        if (!url || !url.length) {
            console.log('no href attribute for ajax component link');
            return;
        }

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload, function () {
                ScrollReveal().sync();
                if(typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                    lui_dialog.open(payload.dialog);
                }
            });
        });
    });
})();

(function fn_pictureFill() {
    if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie")) {
        $.importScript(cdnjs.picturefill);
    }
})();

(function fn_cookieConsent() {
    if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
        $.importScript(cdnjs.cookieconsent);
    }
})();

function fn_reCaptcha() {
    $.lui("lib_reCaptcha", "[data-lib-recaptcha]", function(selector) {
        selector.lib_reCaptcha("lib-recaptcha");
    });
}

// $(function(){
//     $('[data-click]').trigger('click');
// });

setTimeout(fn_reCaptcha,2500);

setInterval(fn_reCaptcha, 150000);