jQuery.lui = function(n,s,callback) {
    (function fn(name, selector) {
        let fns;
        let name_selector;

        if (typeof selector === "string") {
            name_selector = selector.split(" ").splice(-1)[0];

            if ($(selector).length) {
                fns = callback($(selector),{})
            }
        } else {
            if (selector.length) {
                fns = callback(selector,{})
            }
        }

        if (name.indexOf("lib") !== -1) {
            if (typeof window.lui["lib"][name] === "undefined") {
                window.lui["lib"][name] = fn;
                window.lui["lib"][name].selector = selector;
            }
        } else {
            if (typeof window.lui[name][name_selector] === "undefined") {
                window.lui[name][name_selector] = fn;
                window.lui[name][name_selector].selector = selector;
            }

            if (typeof fns !== "undefined") {
                window.lui[name][name_selector].fns = fns;
            }
        }
    })(n,s);
};

const luiLoad = function(options, callback) {

    if (options["name"].includes("layout")) {
        Object.keys(window.lui.layout).forEach(function(key) {
            if ($(key).length) {
                window.lui.layout[key]("layout",key)
            }
        });
    }

    if (options["name"].includes("comp")) {
        Object.keys(window.lui.comp).forEach(function (key) {
            if ($(`${options["selector"]} ${key}`).length) {
                window.lui.comp[key]("comp", `${options["selector"]} ${key}`)
            }
        });
    }

    if (options["name"].includes("part")) {
        Object.keys(window.lui.part).forEach(function (key) {
            if ($(`${options["selector"]} ${key}`).length) {
                window.lui.part[key]("part",`${options["selector"]} ${key}`)
            }
        });
    }

    if (options["name"].includes("lib")) {
        Object.keys(window.lui.lib).forEach(function (key) {
            let s = window.lui.lib[key].selector;
            if ($(`${options["selector"]} ${s}`).length) {
                window.lui.lib[key](key, $(options["selector"]).find(s))
            }
        });
    }

    if (callback) {
        callback()
    }
};