if (typeof Swup !== "undefined") {
    const swup = new Swup({
        containers: ['#layout_main', '#layout_header', '#layout_footer']
    });

    swup.on('clickLink', function (e) {
        $("[data-lib-ssm], .ssm-nav").removeClass('ssm-nav-visible');

        clearTimeout(ssm_timeout);

        html.removeClass("ssm-active");

        ssm_timeout = setTimeout(function(){
            html.removeClass("ssm-nav-visible ssm-opened");
        },1250);

        $(".ssm-overlay").fadeOut(300);
    });

    swup.on('contentReplaced', function () {
        locomotive.destroy();
        $("#layout_scroll").removeAttr("style");
        locomotive.init();

        luiLoad({"name": ["layout"]});

        swup.options.containers.forEach((selector) => {
            luiLoad({"name": ["comp", "part", "lib"], "selector": selector});
        });

        if (typeof fbq !== "undefined") {
            fbq('track', 'PageView');
        }

        if (typeof gtag !== "undefined") {
            let configs = [];
            window.dataLayer.forEach(function(config){
                if (config[0] === "config") {
                    if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                        configs.push(config[1]);
                        gtag('config', config[1], {
                            'page_title': document.title,
                            'page_path': window.location.pathname + window.location.search
                        });
                    }
                }
            });
        }

        if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({
                'event': 'VirtualPageview',
                'virtualPageURL': window.location.pathname + window.location.search,
                'virtualPageTitle': document.title
            });
        }

        if (typeof window.seznam_retargeting_id !== "undefined") {
            $.getScript("https://c.imedia.cz/js/retargeting.js");
        }

        if (typeof window.lui_cookiesconsent !== "undefined") {
            window.lui_cookiesconsent.fn_cookieconsent_init()
        }

        document.body.classList.remove("body--preload");
        document.body.classList.add("body--loaded");
    });
}