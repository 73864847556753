$.fn.lib_map = function (data) {
    let selector = $(this);

    $.importScript(cdnjs.googlemap.replace("APIKEY", data["api"]), function(){
        cssLoaded(function(){
            let zoom = data["zoom"],
                coords = data["coords"].split(/[\s,]+/),
                marker = data["marker"];

            let map = new google.maps.Map(selector[0], {
                zoom: zoom,
                center: {
                    lat: parseFloat(coords[0]), lng: parseFloat(coords[1])
                },
                mapTypeControl: false
            });

            let bounds = new google.maps.LatLngBounds();

            let map_marker_position = new google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1]));

            let image = {
                path: 'M0,60v0v-60v0h60v0v60v0z',
                fillColor: "#80614b",
                strokeOpacity: 0,
                strokeWeight: 0,
                fillOpacity: 1,
                scale: 1,
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(30, 79)
            };

            let map_marker = new google.maps.Marker({
                position: map_marker_position,
                map: map,
                icon: image
            });

            google.maps.event.addListenerOnce(map, 'idle', function(){
                setTimeout(function(){
                    selector.find("canvas").parent().addClass("marker");
                },150);
            });

            google.maps.event.addListener(map_marker, 'click', function(){
                window.open(marker["link"], '_blank');
            });

            bounds.extend(map_marker_position);
        });
    });
};