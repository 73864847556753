$.lui("layout","#layout_header", function() {
    if (document.getElementsByClassName("ssm-overlay").length === 0) {
        $.importScript(cdnjs.touchswipe, function() {
            $('.ssm-nav').lui_ssm();
        });
    }

    // bodyLoaded(function() {
    //         let interval = setInterval(() => {
    //             locomotive.on("scroll", function(e){
    //                 if (typeof locomotive !== "undefined") {
    //                     clearInterval(interval);
    //                     if (document.documentElement.classList.contains("has-scroll-smooth")) {
    //                         if (document.querySelector(".comp_visual") !== null) {
    //                             if (e.delta.y > (document.getElementsByClassName("comp_visual")[0].clientHeight - 64)) {
    //                                 document.getElementById("layout_menu").classList.remove("text--color-invert");
    //                             } else {
    //                                 document.getElementById("layout_menu").classList.add("text--color-invert");
    //                             }
    //                         }
    //                         else {
    //                             document.getElementById("layout_menu").classList.remove("text--color-invert");
    //                         }
    //                     }
    //                 }
    //             });
    //     },100);
    // });
});